import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeCircles } from "react-loader-spinner";
import "./spinner.css";

export const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker({area: 'update-status', delay: 300});

  return (
    promiseInProgress && (
      <div className="spinner">
        <ThreeCircles color="#2BAD60" height="150" width="150" />
      </div>
    )
  );
};

