import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Navigation from '../Navigation/Navigation';

const GridItem = styled(Paper)({
  backgroundColor: 'transparent',
  textAlign: 'center',
  color: '#000',
});

const ClearBg = styled(Box)({
  backgroundColor: '#55d',
  paddingTop: '0px'
})

export default function Header(props) {
  const [drawerState, setDrawerState] = React.useState(false)

  function toggleDrawer(event) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (drawerState) {
      setDrawerState(false);
      return;
    }
    setDrawerState(true);
  }

  return (
    <ClearBg sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} style={{ width: '100%', height: '70px' }}>
          <Grid item>
            <GridItem elevation={0}><Button onClick={toggleDrawer}><p class={'menu'}><Icon>menuRoundedIcon</Icon></p></Button></GridItem>
            <Drawer
              anchor={'left'}
              open={drawerState}
              onClose={toggleDrawer}
            >
              <Box sx={2} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
                <Navigation></Navigation>
              </Box>
            </Drawer>
          </Grid>
          <Grid item xs={11}>
            <GridItem elevation={0}><p class={'menu'}>{props.text}</p></GridItem>
          </Grid>
        </Grid>
    </ClearBg>
  );
}
