import { trackPromise } from 'react-promise-tracker';
import { BASE_URL } from '../utils/constans';

async function retry (id, handleDeleteClick) {
    const response = await trackPromise(fetch(`${BASE_URL}/answers/retryTest/${id}`, {
        method: "GET",
        withCredentials: true,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        }
    }), 'update-status');
    handleDeleteClick();
    if(response && response.status !== 200) {
        // setError(true);
    }
}
export default retry