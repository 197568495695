import React from "react";
import "./question.css";

export const Question = (data) => {
  return (
    <div className="question">
        <p>Order in UI: {data.data.orderInUi}</p>
        <p>Text: {data.data.text}</p>
        <p>Is Boolean?: {data.data.isTrueFalse ? 'Yes' : 'No'}</p>
        <p>Is Multiple Choice?: {data.data.isMultipleChoice ? 'Yes' : 'No'}</p>
        <p>Sub heading: {data.data.sub_heading}</p>
        Sub Questions: {data.data.sub_questions.sort((a,b) => {return a.orderInUi - 
              b.orderInUi}).map((item,i) => <li key={i}>{item.text}</li>)}
    </div>
  );
};

