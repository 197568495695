import React, { useMemo, useState } from "react";
import retry from "../../services/retry";
import useTable from "../../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import { Button, Input } from "@mui/material";
import updateStatus from "../../services/update_status";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CsvDownload from 'react-json-to-csv';
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";

const Table = ({ data, rowsPerPage, deleteButton }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [checkCount, setCheckCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [snackMSG, setSnackMSG] = useState("");
    // const [masterChecked, setMasterChecked] = useState("");
    const [checkList, setCheckList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const setChecked = new Set();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // function onMasterCheck(e) {
    //     setMasterChecked(e.target.checked);
    //     console.log(checkList);
    //     slice.forEach((item) => {
    //         checkList.forEach((itemCheckList) => {
    //           if(itemCheckList.id === item.id) {
    //             itemCheckList.value = e.target.checked;
    //           }
    //         });
    //     });
    // }

    const handleDeleteClick = (text) => {
      const msg = text ? 'Recovery Done' : 'Delete Done'
      setSnackMSG(msg);
      forceUpdate();
      setOpen(true);
      const targetAddress = text ? '/dashboard': '/wastebasket';
      setTimeout(()=> {
        navigate(targetAddress);
      }, 2000);
    };

    const handleRetryClick = () => {
        setSnackMSG("Retry Done");
        forceUpdate();
        setOpen(true);
        setTimeout(()=> {
          navigate("/dashboard");
        }, 2000);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}></Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    
    function onCheckboxCheck(e, id) {
        if (e.target.checked) {
            setChecked.add(id);
            filteredRows.find((item) => item.id === id).checked = true;
            csvData.push(filteredRows.find(function(item) { return item.id === id }));
            setCheckCount(checkCount+1);
        } else {
            setChecked.delete(id);
            filteredRows.find((item) => item.id === id).checked = false;
            setCsvData(csvData.filter(item => item.id !== id));
            setCheckCount(checkCount-1);
        }
        checkList.forEach((item) => {
          if(item.id === id) {
            item.value = e.target.checked;
          }
        });
        // slice.forEach((item) => {
        //     item.checked = e.target.checked;
        // });
        // const result = csvData;
    }

    function convertUTCDateToLocalDate(date) {
      var dateLocal = new Date(date);
      var newDate = new Date(dateLocal.getTime() - 60*1000);
      return newDate.toLocaleString('he-IL');
    }

    function downloadExcel(data) {
      if (data.csvData.length === 0) {
        data.csvData = filteredRows;
      }
      const worksheet = utils.json_to_sheet(data.csvData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "export-ld-calc.xlsx");
    }

    function deleteMultiple(deleteButton) {
      csvData.forEach((item) => {
        updateStatus(item.id, !deleteButton, handleDeleteClick);
      });
    }

    const filteredRows = useMemo(() => {
        data.forEach((item) => item.checked = false);
        setCheckList(data.map((item) => ({ id: item.id, value: false })));
        if (!searchTerm) return data;
    
        if (data.length > 0) {
          const attributes = ['firstName', 'lastName', 'countryOfResidency', 'email'];
          const result = new Set();
          for (const current of data) {
            for (const attribute of attributes) {
              if (attribute === "key") {
                continue;
              }
              if (current[attribute] && current[attribute].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                result.add(current);
              }
            }
          }
          return Array.from(result);
        }
    
        return [];
    }, [searchTerm, data]);

    const { slice, range } = useTable(filteredRows, page, rowsPerPage);
    return (
        <>
            <button onClick={() => deleteMultiple(deleteButton)} style={{padding: '4px 4px', margin: '6px'}}>
              {deleteButton ? 'Delete' : 'Recover' } Multiple ({checkCount})
            </button>
            <button onClick={() => downloadExcel({csvData})} style={{padding: '4px 4px', margin: '6px'}}>
                Download As Excel
            </button>
            <CsvDownload style={{padding: '4px 4px', margin: '6px'}} data={csvData} />
            <Input
                size="lg"
                bordered
                clearable
                placeholder="Search..."
                value={searchTerm}
                style={{padding: '4px 4px', margin: '6px'}}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <table className={styles.table}>
                <thead className={styles.tableRowHeader}>
                    <tr>
                    <th className={styles.tableHeader}>
                      {/* <input
                      type="checkbox"
                      className="form-check-input"
                      checked={masterChecked}
                      id="mastercheck"
                      onChange={(e) => onMasterCheck(e)}
                      /> */}
                    </th>
                    <th className={styles.tableHeader}>Created at</th>
                    <th className={styles.tableHeader}>First name</th>
                    <th className={styles.tableHeader}>Last name</th>
                    <th className={styles.tableHeader}>Country</th>
                    <th className={styles.tableHeader}>Email</th>
                    <th className={styles.tableHeader}>Dyslexia prob</th>
                    <th className={styles.tableHeader}>Dysgraphia prob</th>
                    <th className={styles.tableHeader}>Dyscalculia prob</th>
                    <th className={styles.tableHeader}>ADHD prob</th>
                    <th className={styles.tableHeader}>Dyslexia conclusion</th>
                    <th className={styles.tableHeader}>Dysgraphia conclusion</th>
                    <th className={styles.tableHeader}>Dyscalculia conclusion</th>
                    <th className={styles.tableHeader}>ADHD conclusion</th>
                    <th className={styles.tableHeader}></th>
                    <th className={styles.tableHeader}></th>
                    <th className={styles.tableHeader}>Preview</th>
                    <th className={styles.tableHeader}>Full</th>
                    <th className={styles.tableHeader}>Debug</th>
                    </tr>
                </thead>
                <tbody>
                    {slice.map((el) => (
                    <tr className={styles.tableRowItems} key={el.id}>
                        <td id={el.id} className={styles.tableCell}><input type="checkbox" ischecked={checkList.find((item) => item.id === el.id).value} onChange={(e) => onCheckboxCheck(e, el.id)}/></td>
                        <td className={styles.tableCell}>{convertUTCDateToLocalDate(el.createdAt)}</td>
                        <td className={styles.tableCell}>{el.firstName}</td>
                        <td className={styles.tableCell}>{el.lastName}</td>
                        <td className={styles.tableCell}>{el.countryOfResidency}</td>
                        <td className={styles.tableCell}>{el.email}</td>
                        <td className={styles.tableCell}>{el.dyslexia_prob}</td>
                        <td className={styles.tableCell}>{el.dysgraphia_prob}</td>
                        <td className={styles.tableCell}>{el.dyscalculia_prob}</td>
                        <td className={styles.tableCell}>{el.adhd_conclusion_prob}</td>
                        <td className={styles.tableCell}>{el.dyslexia_conclusion_prob}</td>
                        <td className={styles.tableCell}>{el.dysgraphia_conclusion_prob}</td>
                        <td className={styles.tableCell}>{el.dyscalculia_conclusion_prob}</td>
                        <td className={styles.tableCell}>{el.adhd_prob}</td>
                        <td className={styles.tableCell}><Button variant="outlined" onClick={(e)=> {updateStatus(el.id, !deleteButton, handleDeleteClick)}}>{deleteButton ? 'Delete' : 'Recover'}</Button></td>
                        <td className={styles.tableCell}><Button variant="outlined" onClick={(e)=> {retry(el.id, handleRetryClick)}}>Retry</Button></td>
                        <td className={styles.tableCell}><a href={el.previewSummaryPath}>link</a></td>
                        <td className={styles.tableCell}><a href={el.fullSummaryPath}>link</a></td>
                        <td className={styles.tableCell}><a href={el.debugSummaryPath}>link</a></td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={snackMSG}
                action={action}
            />
        </>
    );
};

export default Table;