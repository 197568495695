import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '../Buttons/Logout';
import { ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function Navigation() {
    const navigate = useNavigate();
    return <List>
                <ListItem key={'submissions'}>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton>
                            <ListItemIcon><EmailIcon></EmailIcon></ListItemIcon>
                            <ListItemText primary={'Submissions'} />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <Link to="/wastebasket" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem key={'wastebasket'}>
                            <ListItemButton>
                                <ListItemIcon><DeleteIcon></DeleteIcon></ListItemIcon>
                                <ListItemText primary={'Waste Basket'} />
                            </ListItemButton>
                    </ListItem>
                </Link>
                <Link to="/questions" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem key={'questions'}>
                            <ListItemButton>
                                <ListItemIcon><QuestionMarkIcon></QuestionMarkIcon></ListItemIcon>
                                <ListItemText primary={'Questions'} />
                            </ListItemButton>
                    </ListItem>
                </Link>
                <ListItem key={'logout'}>
                    <Logout navigate={navigate}></Logout>
                </ListItem>
            </List>
}