import { Icon, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Logout extends React.Component {
    submit = () => {
        confirmAlert({
        title: 'Logout',
        message: 'Are you sure you want to logout? :-(',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    localStorage.removeItem('token');
                    this.props.navigate('/login');
                }
            },
            {
                label: 'No',
                onClick: () => {

                }
            }
        ]
        });
    };

  render() {
    return (
        <ListItemButton onClick={this.submit}>
            <ListItemIcon><Icon>logoutRoundedIcon</Icon></ListItemIcon>
            <ListItemText primary={'Logout'} />
        </ListItemButton>
    );
  }
}