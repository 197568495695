import { trackPromise } from 'react-promise-tracker';
import { BASE_URL } from '../utils/constans';

async function updateStatus(id, status, handleClick) {
    const response = await trackPromise(fetch(`${BASE_URL}/answers/update_status/${id}`, {
        method: "POST",
        withCredentials: true,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ active: status })
    }), 'update-status');
    handleClick(status);
    if(response && response.status !== 200) {
        // setError(true);
    }
}
export default updateStatus;