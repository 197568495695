import { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import Table from "../components/Table";
import { trackPromise } from 'react-promise-tracker';
import { BASE_URL } from '../utils/constans';

function WasteBasket() {

  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setAuthenticated(true);
    }
  }, []);


  const [items, setItems] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          try {
            if (!localStorage.getItem("token")) {
              setAuthenticated(false);
              return;
            }
            const response = await trackPromise(fetch(BASE_URL + "/answers/deleted?order=ASC&page=1&take=1000",{
              method: "GET",
              withCredentials: true,
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
              }
            }));
            const fetchedData = await response.json();
            setItems(fetchedData.sort((a,b) => {return new Date(a.createdAt).getTime() - 
              new Date(b.createdAt).getTime()}).reverse());
          } catch (err) {
              console.log(err);
          }
      }
      (async () => await fetchData())()
  }, []);

  if (!authenticated) {
    // return <Navigate replace to="/login" />;
    return (
      <Box>
        <Header text={"Waste Basket (" + items.length + ")"}></Header>
        <Table data={items} rowsPerPage={10} deleteButton={false}/>
      </Box>
    );
  } else {
    return (
      <Box>
        <Header text={"Waste Basket (" + items.length + ")"}></Header>
        <Table data={items} rowsPerPage={10} deleteButton={false}/>
      </Box>
    );
  }
}
export default WasteBasket;
