import { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import { trackPromise } from 'react-promise-tracker';
import { Question } from '../components/Questions';
import { BASE_URL } from '../utils/constans';

function Questions() {

  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setAuthenticated(true);
    }
  }, []);

  const [items, setItems] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          try {
            if (!localStorage.getItem("token")) {
              setAuthenticated(false);
              return;
            }
            const response = await trackPromise(fetch(BASE_URL + "/questions",{
              method: "GET",
              withCredentials: true,
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
              }
            }));
            const fetchedData = await response.json();
            setItems(fetchedData.sort((a,b) => {return a.orderInUi - 
              b.orderInUi}));
          } catch (err) {
              console.log(err);
          }
      }
      (async () => await fetchData())()
  }, []);

  if (!authenticated) {
    // return <Navigate replace to="/login" />;
    return (
      <Box>
        <Header text={"Questions (" + items.length + ")"}></Header>
        <p>Under construction</p>
      </Box>
    );
  } else {
    return (
      <Box>
        <Header text={"Questions (" + items.length + ")"}></Header>
        {items.map((item,i) => <Question key={i} data={item}></Question>)}
      </Box>
    );
  }
}
export default Questions;
