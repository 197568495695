import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, Routes} from "react-router-dom";
import SignInPage from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import { Spinner } from './components/Spinner/spinner';
import WasteBasket from './pages/WasteBasket';
import Questions from './pages/Questions';

const root = document.getElementById('root');

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress && 
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots color="#55d" height="100" width="100" />
    </div>
};

render(
    <React.StrictMode>
        <HashRouter>
            <Routes>
                <Route index element={<App />} />
                <Route path="login" element={<SignInPage />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="wastebasket" element={<WasteBasket />} />
                <Route path="questions" element={<Questions />} />
            </Routes>
        </HashRouter>
        <LoadingIndicator/>
        <Spinner/>
    </React.StrictMode>,
    root
);

reportWebVitals();
