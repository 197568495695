import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../utils/constans';

function SignInPage() {
    const navigate = useNavigate();
    const [textInput, setTextInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
      const localToken = localStorage.getItem("token");
      if (localToken) {
        navigate("/dashboard");
      }
    }, [navigate]);

    const handleLogin = async () => {
        setIsLoading(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "email": textInput,
            "password": passwordInput,
          })
        };
        const response = await fetch(BASE_URL + '/auth/login', requestOptions)
        const result = await response.json();
        if(result && result.statusCode === 404) {
            setError(true);
        }
        if(result && result.token) {
            localStorage.setItem('token', result.token.accessToken)
            navigate("/dashboard");
        }
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh', backgroundColor: '#eeeeee' }}
        >
            <Card item>
                <div style={{ margin: '20px 40px 40px' }}>
                    <img
                        src="LDC-LOGO-WHITE.png"
                        alt="Logo"
                        style={{ maxHeight: '100px', margin: '0 auto', display: 'block' }}
                    />
                    {isError && <span justify="center" style={{ color: '#f44235' }}>Email or password is incorrect</span>}
                    <Grid container spacing={7} alignItems="flex-end" justify="center" marginTop={-3}>
                        <Grid item>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="username"
                                label="Username"
                                type="email"
                                variant="standard"
                                autoFocus
                                required
                                onChange={event => {
                                    setTextInput(event.target.value);
                                    setError(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={7} alignItems="flex-end" justify="center" marginTop={-4}>
                        <Grid item>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                variant="standard"
                                required
                                onChangeCapture={event => {
                                    setPasswordInput(event.target.value);
                                    setError(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                        <Button
                            onClick={() => handleLogin()}
                            variant="contained"
                            fullWidth
                            color="primary"
                            style={{ textTransform: 'none' }}
                            loading={isLoading}
                        >
                            Login
                        </Button>
                    </Grid>
                </div>
            </Card>
        </Grid>
    );
}

export default SignInPage;